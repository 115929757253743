import CustomDateInput from "components/Inputs/CustomDateInput";
import CustomInput from "components/Inputs/CustomInput";
import CustomNumberInput from "components/Inputs/CustomNumberInput";
import CustomSelect from "components/Inputs/CustomSelect";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
} from "reactstrap";
import { calculateBMI } from "utils/BMI";
import BackBtn from "utils/backBtn";
import { currentDate } from "utils/currentDate";
import { supportMonths } from "variables/options";
import Select from "react-select";
import { NotificationManager } from "react-notifications";
import CustomTable from "components/Table/Table";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPatient } from "store/actions/patient";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import { createSupportPackage } from "store/actions/supportPackage";
import { yesOrNo } from "variables/options";
import { getPackages } from "store/actions/package";
import { isEveryElementIncluded } from "utils/isEveryElementIncluded";
import CustomTextArea from "components/Inputs/CustomTextArea";

const reimbursementInitial = {
  month: null,
  packages: null,
  amount: 0,
  month_year: "",
};

const SupportPackageCreate = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [BMI, setBMI] = useState(0);
  const [reimbursement_BMI, setReimbursementBMI] = useState(0);
  const [selectedPlanPackages, setSelectedPlanPackages] = useState(null);
  const [selectedReceivePackages, setSelectedReceivePackages] = useState(null);
  const [selectedReimbursementStatus, setSelectedReimbursementStatus] =
    useState(null);
  const [selectedReimbursement, setSelectedReimbursement] =
    useState(reimbursementInitial);
  const [selectedReimbursements, setSelectedReimbursements] = useState([]);
  const [selectedReceivedPackageStatus, setSelectedReceivedPackageStatus] =
    useState(null);

  const dispatch = useDispatch();
  const { loading: patientLoading, patient } = useSelector(
    (state) => state.patient
  );
  const { success } = useSelector((state) => state.supportPackage);
  const { packages } = useSelector((state) => state.package);

  const packageOptions = packages?.map((p) => {
    return { value: p, label: `Package ${p.package_no}` };
  });
  const user = useSelector((state) => state.auth.user);
  const isAuthorized =
    user?.email === "phonesuukhaing@theunion.org" ||
    user?.email === "nay.mon@theunion.org" ||
    user?.email === "myanandar.aung@theunion.org";

  useEffect(() => {
    dispatch(getPatient(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getPackages());
  }, [dispatch]);

  useEffect(() => {
    const { height, support_packages } = patient;

    const currentHeight =
      support_packages?.length > 0 &&
      support_packages[support_packages?.length - 1].height
        ? support_packages[support_packages?.length - 1].height
        : height;

    setValue("height", currentHeight);
    setValue("reimbursement_height", currentHeight);
  }, [patient, setValue]);

  const handleAddReimbursement = () => {
    const { month, packages, month_year } = selectedReimbursement;

    // const planPackages = selectedPlanPackages?.map((el) => el.value.package_no);
    // const addReimbursementPackages = packages?.map((el) => el.value.package_no);

    const alreadyExist = selectedReimbursements.find(
      (data) => data.reimbursement_month === month.value
    );

    // if (!isEveryElementIncluded(planPackages, addReimbursementPackages)) {
    //   NotificationManager.warning(
    //     "Please check again Plan and Reimbursement Packages!"
    //   );
    // } else
    if (alreadyExist) {
      NotificationManager.warning("Month has been already taken!");
    } else {
      if (month && packages.length) {
        const amount = packages.reduce(
          (acc, val) => acc + parseInt(val.value.amount),
          0
        );

        setSelectedReimbursements((prev) => [
          ...prev,
          {
            reimbursement_month: month.value,
            amount,
            reimbursement_packages: packages
              ?.map((val) => val.value.package_no)
              .join(","),
            reimbursement_month_year: month_year,
          },
        ]);
        setSelectedReimbursement(reimbursementInitial);
      } else {
        NotificationManager.warning("Please enter month and packages!");
      }
    }
  };

  const handleDeleteReimbursement = (index) => {
    const filterReimbursements = selectedReimbursements.filter(
      (_, i) => i !== index
    );
    setSelectedReimbursements(filterReimbursements);
  };

  const onSubmit = async (values) => {
    setLoading(true);

    const planPackages = values.plan_packages?.map((el) => el.value.package_no);
    const receivePackages =
      values.receive_packages?.map((el) => el.value.package_no) || [];

    if (!isEveryElementIncluded(planPackages, receivePackages)) {
      NotificationManager.warning(
        "Please check again Plan and Receive Packages"
      );
    } else if (
      selectedReimbursementStatus?.value === "Yes" &&
      selectedReimbursements.length === 0
    ) {
      NotificationManager.warning("Please insert reimbursement package!");
    } else {
      await dispatch(
        createSupportPackage({
          township_id: patient?.current_township?.id,
          patient_id: patient?.id,
          date: values.date,
          month: selectedMonth?.value,
          month_year: values.month_year,
          height: values.height,
          weight: values.weight,
          BMI: values.BMI,
          reimbursement_BMI: values.reimbursement_BMI,
          access_packages: values.access_packages
            ?.map((el) => el.value.package_no)
            .join(","),
          plan_packages: values.plan_packages
            ?.map((el) => el.value.package_no)
            .join(","),
          receive_package_status: selectedReceivedPackageStatus?.value,
          receive_packages:
            selectedReceivedPackageStatus?.value === "Yes"
              ? receivePackages.join(",")
              : null,
          reimbursement_status:
            selectedMonth?.value !== -1
              ? selectedReimbursementStatus?.value
              : null,
          amount: values.amount,
          remark: values.remark,
          reimbursement_packages:
            selectedReimbursementStatus?.value === "No"
              ? []
              : selectedReimbursements,
        })
      );
    }

    setLoading(false);
  };

  const formReset = useCallback(() => {
    setSelectedMonth(null);
    setSelectedPlanPackages(null);
    setSelectedReceivedPackageStatus(null);
    setSelectedReceivePackages(null);
    setSelectedReimbursementStatus(null);
    setSelectedReimbursements([]);
    reset();
  }, [reset]);

  useEffect(() => {
    if (success) {
      formReset();
    }

    return () => formReset();
  }, [success, formReset]);

  if (patientLoading) {
    return <FullScreenLoading />;
  }

  const { name, current_township, support_packages, age } = patient;
  return (
    <Container className="mt-3" fluid>
      <BackBtn />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader className="border-0">
            <h3>
              Create Package ({`${name}`}) ({`${current_township?.name}`})
            </h3>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md={4}>
                <CustomDateInput
                  id="date"
                  label="Support Received Date"
                  register={{
                    ...register("date", {
                      required: "Support Received Date is required!",
                    }),
                  }}
                  placeholder="Select Support Received Date"
                  errors={errors}
                  max={currentDate()}
                  onChange={(event) => setDate(event.target.value)}
                />
              </Col>
              <Col md={4}>
                <CustomSelect
                  id="month"
                  label="Support Received Month"
                  rules={{ required: "Support Received Month is required!" }}
                  control={control}
                  options={
                    support_packages?.length === 0
                      ? supportMonths
                      : // .filter((el) => el?.value !== -1)
                        supportMonths?.filter(
                          (el) =>
                            support_packages &&
                            // el?.value !== -1 &&
                            el?.value >
                              support_packages[support_packages?.length - 1]
                                ?.month
                        )
                  }
                  value={selectedMonth}
                  setData={(month) => {
                    setSelectedMonth(month);
                    if (month?.value === -1) {
                      const data = [{ value: packages[6], label: "Package 7" }];
                      setSelectedPlanPackages(data);
                      setValue("plan_packages", data);
                      setSelectedReceivePackages(data);
                      setValue("receive_packages", data);
                    }
                  }}
                  placeholder="Support Received Month"
                />
              </Col>
              <Col md={4}>
                <CustomInput
                  id="month_year"
                  label="Month Year"
                  register={{
                    ...register("month_year", {
                      required: "Month Year is required~",
                    }),
                  }}
                  placeholder="Enter Month Year"
                  errors={errors}
                  type="month"
                  disabled={!date}
                  max={date?.slice(0, 7)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomNumberInput
                  id="height"
                  label="Height(cm)"
                  register={{
                    ...register("height", {
                      required: "Height(cm) is required!",
                    }),
                  }}
                  placeholder="Enter height(cm)"
                  errors={errors}
                  onChange={(event) => {
                    let height = event.target.value * 0.01;
                    let weight = getValues("weight");

                    let BMI = weight / (height * height);
                    if (!isNaN(BMI) && BMI !== Infinity) {
                      setValue("BMI", BMI.toFixed(1));
                      setBMI(BMI.toFixed(1));
                    }
                  }}
                  disabled={
                    !(
                      parseInt(age) <= 18 &&
                      (selectedMonth?.value === 6 ||
                        selectedMonth?.value === 12 ||
                        selectedMonth?.value === 18)
                    )
                  }
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="weight"
                  label="Weight(kg)"
                  register={{
                    ...register("weight", {
                      required: "Weight(kg) is required!",
                    }),
                  }}
                  placeholder="Enter weight(Kg)"
                  errors={errors}
                  onChange={(event) => {
                    let weight = event.target.value;
                    let height = getValues("height") * 0.01;

                    let BMI = weight / (height * height);
                    if (!isNaN(BMI) && BMI !== Infinity) {
                      setValue("BMI", BMI.toFixed(1));
                      setBMI(BMI.toFixed(1));
                    }
                  }}
                  max={132}
                />
              </Col>
              <Col md={4}>
                <CustomNumberInput
                  id="BMI"
                  label={`BMI ${calculateBMI(parseFloat(BMI))}`}
                  register={{
                    ...register("BMI", {
                      required: "BMI is required!",
                    }),
                  }}
                  placeholder="Enter BMI"
                  errors={errors}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomSelect
                  id="plan_packages"
                  label="လူနာ၏ အခက်အခဲများအား ဆန်းစစ်၍ လိုအပ်သဖြင့်ပေးရန် ရည်ရွယ်ပါသည်။"
                  rules={{ required: "Packages is required!" }}
                  control={control}
                  options={packageOptions}
                  value={selectedPlanPackages}
                  setData={setSelectedPlanPackages}
                  placeholder="Packages"
                  isMulti={true}
                  isDisabled={selectedMonth?.value === -1}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <CustomSelect
                  id="receive_package_status"
                  label="ယခုလအတွက် ထောက်ပံမူများ ရရှိပါသလား"
                  rules={{ required: "Receive Package Status is required!" }}
                  control={control}
                  options={yesOrNo}
                  value={selectedReceivedPackageStatus}
                  setData={setSelectedReceivedPackageStatus}
                  placeholder="Received Package Status"
                />
              </Col>
            </Row>

            {selectedReceivedPackageStatus?.value === "Yes" && (
              <Row>
                <Col>
                  <CustomSelect
                    id="receive_packages"
                    label="ယခုလအတွက် ရရှိသော ထောက်ပံမူများ"
                    rules={{ required: "Packages is required!" }}
                    control={control}
                    options={packageOptions}
                    value={selectedReceivePackages}
                    setData={setSelectedReceivePackages}
                    placeholder="Packages"
                    isMulti={true}
                    isDisabled={selectedMonth?.value === -1}
                  />
                </Col>
              </Row>
            )}
            {selectedMonth && selectedMonth?.value !== -1 && (
              <Row>
                <Col md={4}>
                  <CustomSelect
                    id="reimbursement_status"
                    label="Reimbursement Status"
                    rules={{ required: "Reimbursement Status is required!" }}
                    control={control}
                    options={yesOrNo}
                    value={selectedReimbursementStatus}
                    setData={setSelectedReimbursementStatus}
                    placeholder="Reimbursement Status"
                  />
                </Col>
              </Row>
            )}
            {selectedReimbursementStatus?.value === "Yes" && selectedMonth && (
              <>
                <Row>
                  <Col md={4}>
                    <CustomNumberInput
                      id="reimbursement_height"
                      label="Height(cm)"
                      register={{
                        ...register("reimbursement_height", {
                          required: "Height(cm) is required!",
                        }),
                      }}
                      placeholder="Enter height(cm)"
                      errors={errors}
                      onChange={(event) => {
                        let height = event.target.value * 0.01;
                        let weight = getValues("reimbursement_weight");

                        let BMI = weight / (height * height);
                        if (!isNaN(BMI) && BMI !== Infinity) {
                          setValue("reimbursement_BMI", BMI.toFixed(1));
                          setReimbursementBMI(BMI.toFixed(1));
                        }
                      }}
                      disabled={
                        !(
                          parseInt(age) <= 18 &&
                          (selectedMonth?.value === 6 ||
                            selectedMonth?.value === 12 ||
                            selectedMonth?.value === 18)
                        )
                      }
                    />
                  </Col>
                  <Col md={4}>
                    <CustomNumberInput
                      id="reimbursement_weight"
                      label="Weight(kg)"
                      register={{
                        ...register("reimbursement_weight", {
                          required: "Weight(kg) is required!",
                        }),
                      }}
                      placeholder="Enter weight(Kg)"
                      errors={errors}
                      onChange={(event) => {
                        let weight = event.target.value;
                        let height = getValues("reimbursement_height") * 0.01;

                        let BMI = weight / (height * height);
                        if (!isNaN(BMI) && BMI !== Infinity) {
                          setValue("reimbursement_BMI", BMI.toFixed(1));
                          setReimbursementBMI(BMI.toFixed(1));
                        }
                      }}
                      max={132}
                    />
                  </Col>
                  <Col md={4}>
                    <CustomNumberInput
                      id="reimbursement_BMI"
                      label={`BMI ${calculateBMI(
                        parseFloat(reimbursement_BMI)
                      )}`}
                      register={{
                        ...register("reimbursement_BMI", {
                          required: "BMI is required!",
                        }),
                      }}
                      placeholder="Enter BMI"
                      errors={errors}
                      disabled={true}
                    />
                  </Col>
                </Row>
                <h3>ယခင်လများအတွက် လက်ခံရရှိပါက ဖြည့်ရန်</h3>
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <label htmlFor="reimbursement_packages">Packages</label>
                      <Select
                        id="reimbursement_packages"
                        name="reimbursement_packages"
                        options={
                          parseInt(selectedMonth?.value) === 0
                            ? packageOptions.filter(
                                (el) => parseInt(el?.value?.package_no) === 7
                              )
                            : packageOptions
                        }
                        value={selectedReimbursement.packages}
                        onChange={(data) => {
                          setSelectedReimbursement((prev) => ({
                            ...prev,
                            packages: data,
                          }));
                        }}
                        placeholder="Packages"
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <label htmlFor="reimbursement_month">Month</label>
                      <Select
                        id="reimbursement_month"
                        name="reimbursement_month"
                        options={supportMonths.filter(
                          (month) => month.value < selectedMonth?.value
                        )}
                        value={selectedReimbursement.month}
                        onChange={(value) => {
                          setSelectedReimbursement((prev) => ({
                            ...prev,
                            month: value,
                          }));
                        }}
                        placeholder="Month"
                        isSearchable={true}
                        isClearable={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <label htmlFor="reimbursement_month_year">
                        Month Year
                      </label>
                      <input
                        id="reimbursement_month_year"
                        className="form-control"
                        placeholder="Enter Month Year"
                        type="month"
                        value={selectedReimbursement.month_year}
                        onChange={(event) => {
                          setSelectedReimbursement((prev) => ({
                            ...prev,
                            month_year: event.target.value,
                          }));
                        }}
                        disabled={!date}
                        max={date?.slice(0, 7)}
                      />
                      {errors[id] && (
                        <span className="text-danger">
                          {errors[id]?.message}
                        </span>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      size="sm"
                      onClick={handleAddReimbursement}
                      className="mt-4"
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
                <CustomTable
                  header={
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Month</th>
                      <th scope="col">Packages</th>
                      <th scope="col">Month Year</th>
                      <th scope="col">Delete</th>
                    </tr>
                  }
                  body={selectedReimbursements?.map((row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {parseInt(row.reimbursement_month) === -1
                          ? "Pre-enroll Month"
                          : `Month-${row.reimbursement_month}`}
                      </td>
                      <td>
                        {row.reimbursement_packages
                          ?.split(",")
                          ?.map((p) => `Package ${p}`)
                          .join(",")}
                      </td>
                      <td>{row.reimbursement_month_year}</td>
                      <td>
                        <Button
                          onClick={() => handleDeleteReimbursement(index)}
                          color="danger"
                          size="sm"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                />
              </>
            )}
            <Row className="mt-3">
              <Col md={4}>
                <CustomInput
                  id="amount"
                  label="စုစုပေါင်း ရရှိငွေ"
                  register={{
                    ...register("amount", {
                      required: "Amount is required!",
                    }),
                  }}
                  placeholder="Enter Amount"
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <CustomTextArea
                  id="remark"
                  label="Remark"
                  register={{
                    ...register("remark"),
                  }}
                  placeholder="Enter Remark"
                  errors={errors}
                  isRequired={false}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            {isAuthorized && (
              <Button
                color="primary"
                size="sm"
                disabled={loading}
                type="submit"
              >
                {loading ? "Loading" : "Save"}
              </Button>
            )}
          </CardFooter>
        </Card>
      </Form>
    </Container>
  );
};

export default SupportPackageCreate;
