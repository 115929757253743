import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import { getPatient } from "store/actions/patient";
import BackBtn from "utils/backBtn";
import { useParams, useHistory } from "react-router-dom";
import FullScreenLoading from "components/FullScreenLoading/FullScreenLoading";
import CustomTable from "components/Table/Table";
import { temDeletePatient } from "store/actions/patient";
import CustomAlert from "components/Alert/CustomAlert";
import { temDeleteSupportPackage } from "store/actions/supportPackage";

const Field = ({ label, data }) => {
  return (
    <h5>
      <span className="text-danger">{label}</span> {data}
    </h5>
  );
};

const PatientDetail = () => {
  const { id } = useParams();
  const router = useHistory();

  const dispatch = useDispatch();
  const { loading, patient } = useSelector((state) => state.patient);
  const [alert, setAlert] = useState(null);
  const user = useSelector((state) => state.auth);
  const isAuthorized =
    user?.user?.email === "phonesuukhaing@theunion.org" ||
    user?.user?.email === "nay.mon@theunion.org" ||
    user?.user?.email === "myanandar.aung@theunion.org";

  useEffect(() => {
    dispatch(getPatient(id));
  }, [dispatch, id]);

  if (loading) {
    return <FullScreenLoading />;
  }

  const handleDelete = () => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(temDeletePatient(id));
          setAlert(null);
          router.push("/admin/patient-list");
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };

  const handleSupportPackageDelete = (supportId) => {
    setAlert(
      <CustomAlert
        onConfirm={async () => {
          await dispatch(temDeleteSupportPackage(supportId));
          setAlert(null);
          dispatch(getPatient(id));
        }}
        onCancel={() => setAlert(null)}
        toTrash={true}
      />
    );
  };

  const {
    year,
    township,
    RR_code,
    DRTB_code,
    SP_code,
    name,
    DOB,
    age,
    sex,
    treatment_start_date,
    treatment_regimen,
    treatment_regimen_other,
    patient_address,
    patient_phone_no,
    contact_info,
    contact_phone_no,
    primary_language,
    secondary_language,
    height,
    weight,
    BMI,
    TO_status,
    TO_year,
    TO_date,
    TO_RR_code,
    TO_DRTB_code,
    TO_unique_id,
    TO_township,
    TO_townships,
    treatment_finished,
    treatment_finished_date,
    outcome_date,
    outcome,
    remark,
    household_contacts,
    support_packages,
    report_period,
  } = patient;

  return (
    <>
      {alert}
      <Container className="mt-3" fluid>
        <BackBtn />
        <Card>
          <CardHeader className="border-0">
            <Row>
              <Col xs="4">
                <h3 className="mb-0">Patient Detail</h3>
              </Col>
              <Col className="text-right" xs="8">
                <Button
                  size="sm"
                  color="info"
                  onClick={() =>
                    router.push(
                      `/admin/patient-report-outcome-edit/${patient.id}`
                    )
                  }
                >
                  Reported Outcome
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() =>
                    router.push(`/admin/package-create/${patient.id}`)
                  }
                >
                  New Package
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={() =>
                    router.push(`/admin/patient-outcome-edit/${patient.id}`)
                  }
                >
                  Edit Outcome
                </Button>
                <Button
                  size="sm"
                  color="success"
                  onClick={() =>
                    router.push(`/admin/patient-edit/${patient.id}`)
                  }
                >
                  Edit Patient
                </Button>
                {isAuthorized && (
                  <Button
                    color="danger"
                    size="sm"
                    disabled={loading}
                    onClick={handleDelete}
                  >
                    {loading ? "Loading" : "Delete"}
                  </Button>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row className="mb-2">
              <Col md={4}>
                <Field label={"Year - "} data={year} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={3}>
                <Field label={"TIwnship - "} data={township?.name} />
              </Col>
              <Col md={3}>
                <Field label={"Lab Code - "} data={RR_code} />
              </Col>
              <Col md={3}>
                <Field label={"DRTB Code - "} data={DRTB_code} />
              </Col>
              <Col md={3}>
                <Field label={"SP Code - "} data={SP_code} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={3}>
                <Field label={"Name - "} data={name} />
              </Col>
              <Col md={3}>
                <Field label={"DOB - "} data={DOB} />
              </Col>
              <Col md={3}>
                <Field label={"Age - "} data={age} />
              </Col>
              <Col md={3}>
                <Field label={"Sex - "} data={sex} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={3}>
                <Field
                  label={"Treatment Start Date - "}
                  data={treatment_start_date}
                />
              </Col>
              <Col md={3}>
                <Field
                  label={"Treatment Regimen - "}
                  data={treatment_regimen}
                />
              </Col>
              {treatment_regimen_other && (
                <Col md={3}>
                  <Field label={"Other - "} data={treatment_regimen_other} />
                </Col>
              )}
            </Row>
            <Row className="mb-2">
              <Col md={7}>
                <Field label={"Patient Address - "} data={patient_address} />
              </Col>
              <Col md={5}>
                <Field label={"Patient Phone No - "} data={patient_phone_no} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={7}>
                <Field label={"Contact Info - "} data={contact_info} />
              </Col>
              <Col md={5}>
                <Field label={"Contact Phone No - "} data={contact_phone_no} />
              </Col>
            </Row>
            <h5 className="text-danger">Household Contacts</h5>
            <CustomTable
              header={
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Age</th>
                </tr>
              }
              body={household_contacts?.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.name}</td>
                  <td>{row.age}</td>
                </tr>
              ))}
            />
            <Row className="mb-2">
              <Col md={4}>
                <Field label={"Primary Language - "} data={primary_language} />
              </Col>
              <Col md={4}>
                <Field
                  label={"Secondary Language - "}
                  data={secondary_language}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={4}>
                <Field label={"Height(cm) - "} data={height} />
              </Col>
              <Col md={4}>
                <Field label={"Initial Body Weight(kg) - "} data={weight} />
              </Col>
              <Col md={4}>
                <Field label={"BMI - "} data={BMI} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={4}>
                <Field label={"TI Status - "} data={TO_status} />
              </Col>
            </Row>
            {TO_status === "Yes" && (
              <>
                <Row className="mb-2">
                  <Col md={4}>
                    <Field label={"TI Year - "} data={TO_year} />
                  </Col>
                  <Col md={8}>
                    <Field
                      label={"TI Township DRTB Code - "}
                      data={TO_unique_id}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>
                    <Field label={"TI Date - "} data={TO_date} />
                  </Col>
                  <Col md={3}>
                    <Field label={"TI Township - "} data={TO_township?.name} />
                  </Col>
                  <Col md={3}>
                    <Field label={"TI RR Code - "} data={TO_RR_code} />
                  </Col>
                  <Col md={3}>
                    <Field label={"TI DRTB Code - "} data={TO_DRTB_code} />
                  </Col>
                </Row>
                <CustomTable
                  header={
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">TI Year</th>
                      <th>TI Date</th>
                      <th>TI RR Code</th>
                      <th>TI DRTB Code</th>
                      <th>TI Township DRTB Code</th>
                      <th>TI township</th>
                    </tr>
                  }
                  body={TO_townships?.map((ele, index) => (
                    <tr key={ele.id}>
                      <td>{index + 1}</td>
                      <td>{ele.TO_year}</td>
                      <td>{ele.TO_date}</td>
                      <td>{ele.TO_RR_code}</td>
                      <td>{ele.TO_DRTB_code}</td>
                      <td>{ele.TO_unique_id}</td>
                      <td>{ele.TO_township?.name}</td>
                    </tr>
                  ))}
                />
              </>
            )}
            <Row className="mb-2 mt-2">
              <Col md={3}>
                <Field
                  label={"Treatment Finished - "}
                  data={treatment_finished}
                />
              </Col>
              <Col md={3}>
                <Field
                  label={"Finished Date - "}
                  data={treatment_finished_date}
                />
              </Col>
              <Col md={3}>
                <Field label={"Final Outcome - "} data={outcome} />
              </Col>
              <Col md={3}>
                <Field label={"Outcome Date - "} data={outcome_date} />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md={6}>
                <Field label={"Remark - "} data={remark} />
              </Col>
              <Col md={4}>
                <Field label={"Report Peroid - "} data={report_period} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        {support_packages
          ?.filter((sp) => parseInt(sp.status) === 1)
          ?.map((support_package, index) => (
            <Card key={support_package.id}>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">
                      {support_package?.month === -1
                        ? "Pre-enrolled Month"
                        : `Month ${support_package.month}`}{" "}
                      ({`${support_package.township.name}`})
                    </h3>
                  </Col>

                  <Col className="text-right" xs="6">
                    <Button
                      size="sm"
                      color="success"
                      onClick={() =>
                        router.push(
                          `/admin/package-edit/${patient.id}/${support_package.id}`
                        )
                      }
                    >
                      Edit{" "}
                      {support_package?.month === -1
                        ? "Pre-enrolled Month"
                        : `Month ${support_package.month}`}
                    </Button>
                    {/* {index === support_packages.length - 1 && ( */}
                    {isAuthorized && (
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() =>
                          handleSupportPackageDelete(support_package.id)
                        }
                      >
                        Delete{" "}
                        {support_package?.month === -1
                          ? "Pre-enrolled Month"
                          : `Month ${support_package.month}`}
                      </Button>
                    )}

                    {/* )} */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="mb-2">
                  <Col md={4}>
                    <Field
                      label={"Month Year - "}
                      data={support_package.month_year}
                    />
                  </Col>
                  <Col md={4}>
                    <Field
                      label={"Support Received Date - "}
                      data={support_package.date}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={4}>
                    <Field label={"Height - "} data={support_package.height} />
                  </Col>
                  <Col md={4}>
                    <Field
                      label={"Weight(kg) - "}
                      data={support_package.weight}
                    />
                  </Col>
                  <Col md={4}>
                    <Field label={"BMI - "} data={support_package.BMI} />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={6}>
                    <Field
                      label={
                        "လူနာ၏ အခက်အခဲများအား ဆန်းစစ်၍ လိုအပ်သဖြင့်ပေးရန် ရည်ရွယ်ပါသည်။ - "
                      }
                      data={support_package.plan_packages
                        ?.split(",")
                        ?.map((p) => `Package ${p}`)
                        .join(",")}
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      label={"ယခုလအတွက် ရရှိသော ထောက်ပံမူများ - "}
                      data={support_package.receive_packages
                        ?.split(",")
                        ?.map((p) => `Package ${p}`)
                        .join(",")}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={4}>
                    <Field
                      label={"စုစုပေါင်း ရရှိငွေ - "}
                      data={support_package.amount}
                    />
                  </Col>
                  <Col md={4}>
                    <Field label={"Remark - "} data={support_package.remark} />
                  </Col>
                </Row>
                {support_package.reimbursement_status === "Yes" && (
                  <CustomTable
                    header={
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Support Received Month</th>
                        <th scope="col">Packages</th>
                        <th scope="col">Month Year</th>
                      </tr>
                    }
                    body={support_package.reimbursement_packages?.map(
                      (row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {parseInt(row.reimbursement_month) === -1
                              ? "Pre-enroll Month"
                              : `Month-${row.reimbursement_month}`}
                          </td>
                          <td>
                            {row.reimbursement_packages
                              ?.split(",")
                              ?.map((p) => `Package ${p}`)
                              .join(",")}
                          </td>
                          <td>{row.reimbursement_month_year}</td>
                        </tr>
                      )
                    )}
                  />
                )}
              </CardBody>
            </Card>
          ))}
      </Container>
    </>
  );
};

export default PatientDetail;
